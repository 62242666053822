import React from 'react'
import Login from './pages/staff/Login'
import StartSession from './pages/staff/StartSession'
import SessionStarted from './pages/staff/SessionStarted'
import { Routes, Route } from 'react-router-dom'
import CloseSession from './pages/staff/CloseSession'
import SessionClosed from './pages/staff/SessionClosed'
import Main from './pages/admin/Main'
import AdminLogin from './pages/admin/Login'
import Section from './pages/admin/Section'
import Web from './pages/web/Web'

export default function App() {
  return (
    <Routes>
      {/* Web Routes */}
      <Route path="/" element={<Web />} />
      {/* Staff Routes */}
      <Route path="/staff/login" element={<Login />} />
      <Route path="/staff/session-exist" element={<StartSession />} />
      <Route path="/staff/session-started" element={<SessionStarted />} />
      <Route path="/staff/close-session" element={<CloseSession />} />
      <Route path="/staff/session-closed" element={<SessionClosed />} />
      {/* Admin Routes */}
      <Route path="/admin/login" element={<AdminLogin />} />
      <Route path="/admin/dashboard" element={<Main />} />
      <Route path="/admin/section" element={<Section />} />
    </Routes>
  )
}
