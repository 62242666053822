import React, { useState, useEffect } from 'react';
import { MdPerson, MdSecurity, MdSensorOccupied } from "react-icons/md";
import { images } from '../../assets/images';
import { useSelector } from 'react-redux';

export default function StartSession() {

    const session_data = useSelector((state) => state.Reducers.session_data);
    const timeOnly = session_data?.start_time;

    const dateToday = new Date().toISOString().split('T')[0]; // Get current date in YYYY-MM-DD


    return (
        <div className='h-screen space-y-3 w-screen flex flex-col items-center font-SUSE bg-white'>
            <img src={images.logo} className='h-[20%] w-[88%] object-contain' alt='Logo' />

            {/* Surveillance Message */}
            <div className='bg-yellow-100 p-6 rounded-lg shadow-md w-[88%] flex flex-col justify-center items-center text-center '>
                <MdSensorOccupied className='text-6xl text-red-600 mb-2 ' />
                <h1 className='text-2xl font-bold text-red-700'>
                    Session Already Started
                </h1>
                <h2 className='text-xl font-semibold text-gray-700'>
                    Session Started by {session_data?.employee}
                </h2>
            </div>

            {/* Personal Information */}
            <div className='bg-gray-100 p-4 rounded-lg shadow-md mt-6 w-[88%] flex flex-col items-center'>
                <MdPerson className='text-6xl text-gray-600 mb-2' />
                <h2 className='text-xl font-semibold text-gray-700'>
                    Occupied by, {session_data?.employee}
                </h2>
                <p className='text-lg text-gray-600'>
                    Position: {session_data?.section} Staff
                </p>
            </div>

            {/* Time and Date */}
            <div className='bg-green-100 p-4 rounded-lg shadow-md mt-4 w-[88%] text-center'>
                <p className='text-lg font-semibold text-green-700'>
                    Current Time: {timeOnly}
                </p>
                <p className='text-lg font-semibold text-green-700'>
                    Date: {dateToday}
                </p>
            </div>

            {/* Footer */}
            <footer className='mt-auto my-4 text-sm text-gray-400'>
                &copy; 2024 nerdtech. All rights reserved.
            </footer>
        </div>
    );
}
