import React from 'react'
import { images } from '../../assets/images'

export default function AdminLogin() {
    return (
        <div className=' h-screen w-screen bg-slate-100 flex flex-col justify-center items-center font-SUSE'>

            <div className="h-[500px] w-[600px] shadow-lg rounded-lg bg-white flex flex-col justify-center items-center">
                <img src={images.logo} className='h-[100px] w-[200px] object-contain ' alt='Logo' />
                <h1 className=' text-center font-medium text-lg'>
                    Sign in to your account to continue
                </h1>
                <form
                    target=''
                    className='flex flex-col justify-center space-y-3 items-center rounded-xl w-full h-[320px]'>
                    <label className='text-md font-medium text-start w-[88%] text-gray-500'>Email</label>
                    <input type="email" placeholder='Email' className='w-[88%] h-[50px] border rounded-lg px-3' />
                    <label className='text-md font-medium text-start w-[88%] text-gray-500'>Password</label>
                    <input type="password" placeholder='Password' className='w-[88%] h-[50px] border rounded-lg px-3' />
                    <button className='bg-primary text-white w-[300px] font-semibold h-[50px] rounded-lg '>
                        Sign in
                    </button>
                </form>
            </div>
        </div>
    )
}
