import React from 'react';
import { images } from '../../assets/images';
import { motion } from 'framer-motion';
import { MdSupportAgent } from 'react-icons/md';
import { Helmet } from 'react-helmet';

export default function Web() {
    const data = [
        {
            id: 1,
            name: 'Restaurant',
            image: images.restaurant,
            description: 'Monitor guest check-ins and table turnovers with ease.',
        },
        {
            id: 2,
            name: 'Events',
            image: images.event,
            description: 'Track attendee flow and manage event logistics efficiently.',
        },
        {
            id: 3,
            name: 'Industry',
            image: images.industry,
            description: 'QR-based solution for monitoring tasks via IN and OUT scans',
        },
    ];

    return (
        <>
            {/* SEO with React Helmet */}
            <Helmet>
                <title>ScanSync - QR-based Task Monitoring Solution</title>
                <meta name="description" content="ScanSync provides a seamless, smart, and synchronized QR-based solution for efficient task monitoring in restaurants, events, and various industries." />
                <meta name="keywords" content="QR, task monitoring, restaurant check-ins, event logistics, industry solutions, ScanSync" />
                <meta name="author" content="NerdTech" />
                <meta property="og:title" content="ScanSync - QR-based Task Monitoring Solution" />
                <meta property="og:description" content="A seamless, smart, synchronized QR-based solution for task monitoring in various sectors including restaurants, events, and industries." />
                <meta property="og:image" content={images.logo} />
                <meta property="og:url" content="https://www.example.com" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="ScanSync - QR-based Task Monitoring Solution" />
                <meta name="twitter:description" content="ScanSync offers an innovative, QR-based solution for seamless task management in restaurants, events, and industries." />
                <meta name="twitter:image" content={images.logo} />
            </Helmet>

            {/* Header */}
            <div className="w-full shadow-lg h-[60px] sticky top-0 z-50 flex justify-around items-center bg-white font-SUSE">
                <div className="flex items-center space-x-5">
                    <img src={images.logo} className='h-[50px] w-[200px] object-cover' alt='Logo' />
                </div>
                <button className='px-3 py-2 rounded-lg bg-primary text-white'>
                    Contact us
                </button>
            </div>

            {/* Banner */}
            <div className="h-[94vh] w-full flex flex-col justify-center items-center bg-cover bg-no-repeat"
                style={{
                    backgroundImage: `url(${images.bg})`,
                }}>
                <motion.div
                    initial={{
                        transform: "translateZ(4px) translateY(-2px)",
                    }}
                    animate={{
                        transform: "translateZ(32px) translateY(-20px)",
                    }}
                    transition={{
                        repeat: Infinity,
                        repeatType: "mirror",
                        duration: 1,
                        ease: "easeInOut",
                    }}
                    className="text-center flex flex-col justify-center items-center transform -translate-x-1/2 -translate-y-1/2 text-black font-bold"
                >
                    <img src={images.logo} className='h-[200px] w-[500px] object-contain' alt='Logo' />
                    <h1 className='text-3xl font-SUSE font-semibold'>
                        QR-based solution for seamless task monitoring.
                    </h1>
                    <button className='px-3 py-1 mt-4 bg-primary font-semibold text-white'>
                        Get in Touch
                    </button>
                </motion.div>
            </div>

            {/* Features */}
            <div className="py-20 bg-white">
                <div className="container mx-auto px-4">
                    <h1 className='text-5xl text-primary font-SUSE font-semibold text-center mb-10'>
                        ScanSync: Seamless, Smart, Synchronized.
                    </h1>
                    <h1 className='text-3xl font-SUSE font-semibold text-center mb-10'>
                        Best Suited for
                    </h1>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        {data.map((feature, index) => (
                            <motion.div
                                key={index}
                                className="flex flex-col justify-around bg-white space-y-3 shadow-lg rounded-lg items-center p-6"
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5, delay: index * 0.2 }}
                                whileHover={{ scale: 1.05 }}
                            >
                                <img src={feature.image} className="w-full rounded-t-lg h-[200px]" alt={feature.name} />
                                <h1 className="text-3xl text-center font-SUSE font-semibold">{feature.name}</h1>
                                <p className="text-center font-SUSE">{feature.description}</p>
                            </motion.div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Working */}
            <div className="md:h-screen flex justify-center items-center">
                <img src={images.work} className='h-full w-full object-contain md:object-cover' alt='Work' />
            </div>

            {/* Contact Section */}
            <div className="bg-slate-50 py-20">
                <div className="container mx-auto px-4 text-center">
                    <h1 className='text-5xl text-primary font-SUSE font-semibold mb-6'>
                        Get In Touch
                    </h1>
                    <h1 className='text-2xl font-SUSE font-semibold mb-6'>
                        Fill out the form below to start your journey with ScanSync or contact us for more information.
                    </h1>
                    <button className='px-6 py-3 bg-primary rounded-lg font-semibold text-white'>
                        Contact Now
                    </button>
                </div>
            </div>

            {/* Footer */}
            <footer className="bg-slate-50 py-10">
                <div className="container mx-auto px-4">
                    <div className="flex flex-wrap justify-between items-center">

                        <div className="w-full md:w-1/3 mb-6 md:mb-0">
                            <img src={images.logo} className='h-[50px] w-[200px] object-cover' alt='Logo' />
                            <p className="mt-3 text-gray-500">Your one-stop solution for seamless synchronization and scanning.</p>
                        </div>

                        <div className="w-full md:w-1/3 flex justify-center md:justify-end space-x-6">
                            <a href="#" className="text-gray-500 hover:text-primary">Facebook</a>
                            <a href="#" className="text-gray-500 hover:text-primary">Twitter</a>
                            <a href="#" className="text-gray-500 hover:text-primary">LinkedIn</a>
                        </div>
                    </div>

                    <div className="border-t border-gray-700 pt-4 text-center mt-4">
                        <p className="text-sm text-gray-500">&copy; 2024 nerdtech. All rights reserved.</p>
                    </div>
                </div>
            </footer>
            <a
                href='tel:+919405649047'
                className='fixed bottom-8 right-4 bg-primary text-white font-SUSE font-bold px-4 py-4 rounded-full shadow-lg hover:bg-green-600 transition duration-300 ease-in-out'>
                <MdSupportAgent className='text-2xl' />
            </a>
        </>
    );
}
