import React from 'react'
import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";
import { MdCancel } from "react-icons/md";
import { Bar, Pie } from 'react-chartjs-2'; // Placeholder for graph
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend);
export default function Section() {
    const data = [
        { id: 1, name: "Item 1" },
        { id: 2, name: "Item 2" },
        { id: 3, name: "Item 3" },
        { id: 4, name: "Item 4" },
    ];
    const chartData = {
        labels: ['John', 'Alice', 'Michael', 'Sarah', 'David'], // Example staff names
        datasets: [
            {
                label: 'Tasks Completed',
                data: [12, 19, 8, 15, 10], // Example contribution data 0 123 255
                backgroundColor: 'rgba(0, 123, 255, 0.6)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1,
            },
        ],
    };
    const piedata = {
        labels: ["Customer", "Business"],
        datasets: [
            {
                data: [12, 29],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                ],
                borderWidth: 1,
            },
        ],
    };

    // Options for the bar chart
    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
        },
    };
    const employees = [
        { id: 1, name: 'John Doe', intime: '09:00 AM', outtime: '05:00 PM', autoclose: true, notes: 'Completed all tasks' },
        { id: 2, name: 'Alice Johnson', intime: '09:30 AM', outtime: '06:00 PM', autoclose: false, notes: 'Needs to improve time management' },
        { id: 3, name: 'Michael Smith', intime: '08:45 AM', outtime: '04:30 PM', autoclose: true, notes: 'Left early due to personal reasons' },
        { id: 4, name: 'Sarah Lee', intime: '09:15AM', outtime: '05:30 PM', autoclose: true, notes: 'Completed all tasks' },
        { id: 4, name: 'Sarah Lee', intime: '09:15AM', outtime: '05:30 PM', autoclose: true, notes: 'Completed all tasks' },
        { id: 4, name: 'Sarah Lee', intime: '09:15AM', outtime: '05:30 PM', autoclose: true, notes: 'Completed all tasks' },
        { id: 4, name: 'Sarah Lee', intime: '09:15AM', outtime: '05:30 PM', autoclose: false, notes: 'Completed all tasks' },
    ];
    const staffContribution = [
        { id: 1, name: 'John Doe', contribution: '80%' },
        { id: 2, name: 'Alice Johnson', contribution: '90%' },
        { id: 3, name: 'Michael Smith', contribution: '75%' },
    ];
    const [current, setCurrent] = React.useState(0)
    const [show, setShow] = React.useState(false)
    return (
        <div className='w-[100%] bg-slate-100 h-[100%] overflow-y-scroll flex flex-col font-SUSE'>
            <div className='w-full h-[60px] overflow-x-scroll flex space-x-4 items-center'>
                {
                    data.map((item, index) => (
                        <button
                            onClick={() => {
                                setCurrent(index)
                            }}
                            className={`  px-3 py-1 text-lg  font-semibold  ${current === index ? "bg-primary text-white" : "border border-primary text-primary bg-white"}`} >
                            {item.name}
                        </button>
                    ))
                }
            </div>
            {
                show ?
                    <div
                        class="fixed inset-0 p-4 flex flex-wrap justify-center items-center w-full h-full z-[1000] before:fixed before:inset-0 before:w-full before:h-full before:bg-[rgba(0,0,0,0.5)] overflow-auto font-SUSE">
                        <div class="w-full max-w-lg bg-white shadow-lg rounded-lg p-6 relative">
                            <div class="flex items-center pb-3 border-b border-gray-300">
                                <h3 class="text-gray-800 text-xl font-bold flex-1">Image Viewer</h3>
                                <svg xmlns="http://www.w3.org/2000/svg" class="w-3 ml-2 cursor-pointer shrink-0 fill-gray-400 hover:fill-red-500"
                                    viewBox="0 0 320.591 320.591">
                                    <path
                                        d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z"
                                        data-original="#000000"></path>
                                    <path
                                        d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z"
                                        data-original="#000000"></path>
                                </svg>
                            </div>

                            <div class="my-6">
                                <div className=' w-auto h-auto bg-slate-100' />
                            </div>

                            <div class="border-t border-gray-300 pt-6 flex justify-end gap-4">
                                <button
                                    onClick={() => {
                                        setShow(!show)
                                    }}
                                    type="button"
                                    class="px-4 py-2 rounded-lg text-gray-800 text-sm border-none outline-none tracking-wide bg-gray-200 hover:bg-gray-300 active:bg-gray-200">Close</button>
                            </div>
                        </div>
                    </div>
                    :
                    null
            }
            <div className='bg-white p-6 flex justify-between items-center w-full h-[300px] rounded-lg shadow-md '>
                <div className='bg-white '>
                    <div className='overflow-x-auto'>
                        <table className='min-w-full table-auto'>
                            <thead>
                                <tr className='bg-gray-200'>
                                    <th className='px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                                        ID
                                    </th>
                                    <th className='px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                                        Name
                                    </th>
                                    <th className='px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                                        Contribution
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {staffContribution.map((staff) => (
                                    <tr key={staff.id} className='border-t'>
                                        <td className='px-3 py-4 whitespace-nowrap text-md'>{staff.id}</td>
                                        <td className='px-3 py-4 whitespace-nowrap text-md'>{staff.name}</td>
                                        <td className='px-3 py-4 whitespace-nowrap text-md'>{staff.contribution}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <Bar className=' font-SUSE' data={chartData} options={chartOptions} />
                <Pie data={piedata} />
            </div>
            <div className='bg-white p-6 rounded-lg shadow-md mt-8'>
                <h2 className='text-2xl font-bold mb-4'>Work Detail</h2>
                <table className='table-auto w-full'>
                    <thead>
                        <tr className='bg-gray-200 text-left'>
                            <th className='px-4 py-2'>#</th>
                            <th className='px-4 py-2'>Name</th>
                            <th className='px-4 py-2'>In-Time</th>
                            <th className='px-4 py-2'>Out-Time</th>
                            <th className='px-4 py-2'>Auto-Close</th>
                            <th className='px-4 py-2'>Notes</th>
                            <th className='px-4 py-2'>Image</th>
                        </tr>
                    </thead>
                    <tbody>
                        {employees.map((employee) => (
                            <tr key={employee.id} className='border-t'>
                                <td className='px-4 py-2'>{employee.id}</td>
                                <td className='px-4 py-2'>{employee.name}</td>
                                <td className='px-4 py-2'>{employee.intime}</td>
                                <td className='px-4 py-2'>{employee.outtime}</td>
                                <td className='px-4 py-2 text-center'>
                                    {
                                        employee.autoclose ?
                                            <IoCheckmarkDoneCircleSharp className=' text-secondary' />
                                            :
                                            <MdCancel className=' text-red-500' />
                                    }
                                </td>
                                <td className='px-4 py-2'>{employee.notes}</td>
                                <td className='px-4 py-2'>
                                    <button
                                        onClick={() => {
                                            setShow(!show)
                                        }}
                                        className=' px-3 py-1 bg-primary text-white'>
                                        <p>
                                            View
                                        </p>
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

        </div>
    )
}
