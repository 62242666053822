import React from 'react'
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { TbLogout2 } from "react-icons/tb";
import { FaPeopleGroup } from "react-icons/fa6";
import { MdNotificationsNone, MdOutlineDashboard, MdEmojiPeople, MdOutlineMedicalServices, MdOutlineSettings } from "react-icons/md";
import { images } from '../assets/images';
import { Link } from 'react-router-dom';
export default function SideBar({
    setPath
}) {
    return (
        <Sidebar
            backgroundColor='#fff'
            className=' fixed left-0 top-0 w-[25%] h-screen bg-primary font-SUSE '
            menuItemStyles={{
                button: {
                    // the active class will be added automatically by react router
                    // so we can use it to style the active menu item
                    [`&.active`]: {
                        backgroundColor: '#13395e',
                        color: '#b6c8d9',
                    },
                },
            }}
        >
            <Menu className=' flex justify-center items-center flex-col'>
                <img src={images.logo} alt='logo' className='w-full h-auto py-3 object-contain' />
                <h1 className='font-semibold text-gray-400 px-4 text-sm'>
                    Main
                </h1>
                <MenuItem
                    onClick={() => {
                        setPath('dashboard');
                    }}
                    icon={
                        <MdOutlineDashboard />
                    }> Dashboard </MenuItem>
                <h1 className='font-semibold text-gray-400 px-4 text-sm'>
                    Departments
                </h1>
                <SubMenu
                    icon={
                        <img src={images.broom} alt='logo' className='w-4 h-4 object-contain' />
                    }
                    label="House Keeping">
                    <MenuItem
                        onClick={() => {
                            setPath('section');
                        }}
                    > Pie charts </MenuItem>
                    <MenuItem> Line charts </MenuItem>
                </SubMenu>
                <SubMenu
                    icon={
                        <img src={images.repair} alt='logo' className='w-4 h-4 object-contain' />
                    }
                    label="Maintenance">
                    <MenuItem> Pie charts </MenuItem>
                    <MenuItem> Line charts </MenuItem>
                </SubMenu>
                <h1 className='font-semibold text-gray-400 px-4 text-sm'>
                    Staff
                </h1>
                <SubMenu
                    icon={
                        <MdEmojiPeople />
                    }
                    label="Staff Management">
                    <MenuItem> View Staff </MenuItem>
                    <MenuItem> Add Staff </MenuItem>
                </SubMenu>
                <h1 className='font-semibold text-gray-400 px-4 text-sm'>
                    Settings & Notification
                </h1>
                <MenuItem icon={
                    <MdNotificationsNone />
                }
                    suffix={
                        <div className='h-4 w-4 bg-accent rounded-full text-xs text-white font-semibold justify-center items-center flex'>
                            6
                        </div>
                    }
                >Notification</MenuItem>
                <MenuItem icon={
                    <MdOutlineSettings />
                }>Settings</MenuItem>
                <MenuItem icon={
                    <TbLogout2 />
                } className=' text-red-600 font-semibold'> Logout </MenuItem>
            </Menu>
        </Sidebar>
    )
}
