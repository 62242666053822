import React, { useState, useEffect } from 'react';
import { MdPerson, MdSecurity } from "react-icons/md";
import { images } from '../../assets/images';
import { useSelector } from 'react-redux';

export default function SessionStarted() {

    const session_data = useSelector((state) => state.Reducers.session_data);

    const dateObject = new Date(session_data?.start_time);

    const normalDate = dateObject?.toLocaleDateString();
    const normalTime = dateObject?.toLocaleTimeString();


    return (
        <div className='h-screen space-y-3 w-screen flex flex-col items-center font-SUSE bg-white'>
            <img src={images.logo} className='h-[20%] w-[88%] object-contain' alt='Logo' />

            {/* Surveillance Message */}
            <div className='bg-yellow-100 p-6 rounded-lg shadow-md w-[88%] flex flex-col justify-center items-center text-center '>
                <MdSecurity className='text-6xl text-yellow-600 mb-2 ' />
                <h1 className='text-2xl font-bold text-yellow-700'>
                    Session Started
                </h1>
                <p className='text-lg text-yellow-600 mt-2'>
                    You are now under surveillance in {session_data?.section} Department .
                </p>
            </div>

            {/* Personal Information */}
            <div className='bg-gray-100 p-4 rounded-lg shadow-md mt-6 w-[88%] flex flex-col items-center'>
                <MdPerson className='text-6xl text-gray-600 mb-2' />
                <h2 className='text-xl font-semibold text-gray-700'>
                    Welcome, {session_data?.employee}
                </h2>
                <p className='text-lg text-gray-600'>
                    Position: {session_data?.section} Staff
                </p>
            </div>

            {/* Time and Date */}
            <div className='bg-green-100 p-4 rounded-lg shadow-md mt-4 w-[88%] text-center'>
                <p className='text-lg font-semibold text-green-700'>
                    Current Time: {normalTime}
                </p>
                <p className='text-lg font-semibold text-green-700'>
                    Date: {normalDate}
                </p>
            </div>

            {/* Footer */}
            <footer className='mt-auto my-4 text-sm text-gray-400'>
                &copy; 2024 nerdtech. All rights reserved.
            </footer>
        </div>
    );
}
