import React, { useState } from 'react';
import { images } from '../../assets/images';
import OtpInput from 'react-otp-input';
import { MdSupportAgent } from "react-icons/md";
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { startSession } from '../../store/actions/staffActions';

export default function Login() {

    const [otp, setOtp] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const state = useLocation()
    const query = new URLSearchParams(state.search);
    const session_id = query.get('section_id')

    return (
        <div className='h-screen w-screen flex flex-col items-center font-SUSE bg-white'>
            {/* Logo */}
            <img src={images.logo} className='h-[20%] w-[88%] object-contain ' alt='Logo' />

            {/* Main Content */}
            <div className='space-y-4 w-full items-center flex flex-col'>
                <h1 className='font-SUSE font-bold text-text text-2xl'>
                    Staff Login
                </h1>
                <p className='font-SUSE text-lg text-gray-600'>
                    Enter your 4-digit PIN to login
                </p>

                {/* OTP Input */}
                <OtpInput
                    value={otp}
                    onChange={setOtp}
                    containerStyle={{
                        width: '80%',
                        display: 'flex',
                        justifyContent: 'space-around',
                    }}
                    inputStyle={{
                        width: '50px',
                        height: '50px',
                        margin: '10px',
                        border: "1px solid #333333",
                        borderRadius: "5px",
                        fontSize: '1.5rem',
                        textAlign: 'center',
                    }}
                    numInputs={4}
                    inputType="number"
                    renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                />

                {/* Error Message */}
                {errorMessage && (
                    <p className='text-red-500 text-sm font-SUSE'>
                        {errorMessage}
                    </p>
                )}

                {/* Submit Button */}
                <button
                    onClick={() => {
                        dispatch(startSession(setLoading, otp, session_id, navigate))
                    }}
                    className='bg-primary text-white font-SUSE font-bold px-6 py-3 rounded-lg hover:bg-blue-600 transition duration-300 ease-in-out w-[88%]'>
                    {loading ? 'Starting Session...' : 'Start'}
                </button>

                {/* Loading Spinner */}
                {loading && (
                    <div className='mt-2'>
                        <svg
                            className="animate-spin h-5 w-5 text-primary"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                            ></circle>
                            <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                            ></path>
                        </svg>
                    </div>
                )}
            </div>

            {/* Floating Support Button */}
            <a
                href='tel:+919405649047' // Replace with the actual support number
                className='fixed bottom-8 right-4 bg-primary text-white font-SUSE font-bold px-4 py-4 rounded-full shadow-lg hover:bg-green-600 transition duration-300 ease-in-out'>
                <MdSupportAgent className=' text-2xl' />
            </a>

            {/* Footer */}
            <footer className='mt-auto mb-4 text-sm text-gray-400'>
                &copy; 2024 nerdtech. All rights reserved.
            </footer>
        </div>
    );
}
