import axios from "axios"
import { baseURL } from "../../helper/Helper"
import { toast } from 'react-toastify'

export const startSession = (setLoading, pin_code, session_id, navigate) => {
    setLoading(true)
    return async (dispatch) => {
        try {
            await axios.post(baseURL + `start-session/${pin_code}/${session_id}/`)
                .then((response) => {
                    setLoading(false)
                    dispatch({ type: "START_SESSION", payload: response?.data?.data })
                    toast.success(response?.data?.message, {
                        position: 'top-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: 'light',
                        style: {
                            fontFamily: 'SUSE'
                        }
                    })
                    console.log(response.data)
                    if (response?.data?.status && !response?.data?.end) {
                        navigate("/staff/session-exist")
                    }
                    else if (!response?.data?.status && response?.data?.end) {
                        navigate(`/staff/close-session/?session_id=${session_id}`)
                    }
                    else {
                        navigate("/staff/session-started")
                    }

                })
                .catch((error) => {
                    setLoading(false)

                    toast.error(error?.response?.data?.message, {
                        position: 'top-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: 'light',
                        style: {
                            fontFamily: 'SUSE'
                        }
                    })
                })
        }
        catch (error) {
            setLoading(false)
            toast.error("Something Went Wrong!!!", {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: 'light',
                style: {
                    fontFamily: 'SUSE'
                }
            })
        }
    }
}

export const endSession = (setLoading, session_id, navigate, notes, image) => {
    setLoading(true);
    let formData = new FormData();
    formData.append('note', notes);
    formData.append('image', image);
    return async (dispatch) => {

        try {
            const response = await axios.post(`${baseURL}end-session/${session_id}/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',  // Ensure the form data is sent as multipart
                }
            });

            setLoading(false);
            toast.success(response?.data?.message, {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: 'light',
                style: {
                    fontFamily: 'SUSE'
                }
            });
            navigate("/staff/session-closed");
        } catch (error) {
            setLoading(false);

            const errorMessage = error?.response?.data?.message || "Something Went Wrong!!!";

            toast.error(errorMessage, {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: 'light',
                style: {
                    fontFamily: 'SUSE'
                }
            });
            console.error('Error during end session:', error);
        }
    }
}

