import React from 'react';
import { images } from '../../assets/images';
import { IoCheckmarkDoneCircle } from "react-icons/io5";
export default function SessionClosed() {


    return (
        <div className='h-screen w-screen flex flex-col items-center font-SUSE bg-white'>
            {/* Logo */}
            <img src={images.logo} className='h-20 w-80 object-contain my-4' alt='Logo' />

            {/* Main Content */}
            <div className='space-y-4 w-full max-w-xs items-center flex flex-col'>
                <IoCheckmarkDoneCircle className=' text-9xl text-secondary' />
                <h1 className='font-SUSE font-bold text-text text-2xl text-center'>
                    Session Closed
                </h1>
                <p className='font-SUSE text-lg text-gray-600 text-center'>
                    Your session has been successfully closed.
                </p>
            </div>

            {/* Footer */}
            <footer className='mt-auto mb-4 text-sm text-gray-400'>
                &copy; 2024 nerdtech. All rights reserved.
            </footer>
        </div>
    );
}
