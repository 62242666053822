export const images = {
    logo: require('./images/logo.png'),
    broom: require('./images/broom.png'),
    repair: require('./images/repair.png'),
    bg: require('./images/bg.jpg'),
    event: require('./images/event.png'),
    industry: require('./images/industry.png'),
    restaurant: require('./images/restaurant.png'),
    work: require('./images/Work.png'),


}