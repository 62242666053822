import React, { useState, useEffect, useRef } from 'react';
import { MdClose } from "react-icons/md";
import { FiCamera } from "react-icons/fi";
import { images } from '../../assets/images';
import { useDispatch } from 'react-redux';
import { endSession } from '../../store/actions/staffActions';
import { useLocation, useNavigate } from 'react-router-dom';

export default function CloseSession() {
    const [notes, setNotes] = useState('');
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [currentTime, setCurrentTime] = useState(new Date().toLocaleTimeString());
    const [currentDate, setCurrentDate] = useState(new Date().toLocaleDateString());
    const canvasRef = useRef(null);

    const state = useLocation();
    const query = new URLSearchParams(state.search);
    const session_id = query.get('session_id');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // Update time every second
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(new Date().toLocaleTimeString());
            setCurrentDate(new Date().toLocaleDateString());
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    // Draw image and date-time on canvas when the image is set
    const drawOnCanvas = (imgFile) => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        const img = new Image();

        img.src = URL.createObjectURL(imgFile);
        img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
            context.drawImage(img, 0, 0);

            // Draw date and time on the image
            context.font = '20px Arial';
            context.fillStyle = 'white';
            context.fillText(`Date: ${currentDate}`, 10, 30);
            context.fillText(`Time: ${currentTime}`, 10, 60);
        };
    };

    // Trigger canvas drawing whenever a new image is selected
    useEffect(() => {
        if (image) {
            drawOnCanvas(image);
        }
    }, [image, currentTime, currentDate]);

    // Handle session closure with notes and image
    const handleCloseSession = () => {
        if (!notes || !image) {
            alert('Please provide both notes and an image to close the session.');
            return;
        }
        dispatch(endSession(setLoading, session_id, navigate, notes, image));
    };

    return (
        <div className='h-screen w-screen flex flex-col items-center font-SUSE bg-white'>
            {/* Header */}
            <img src={images.logo} className='h-[20%] w-[88%] object-contain ' alt='Logo' />

            {/* Image Upload */}
            <div className='bg-gray-100 p-4 rounded-lg shadow-lg w-[88%] max-w-sm mx-auto flex flex-col justify-center items-center text-center mt-6'>
                <label htmlFor='camera' className='w-full'>
                    <div className="w-full flex justify-center items-center">
                        <FiCamera className=' text-6xl' />
                    </div>
                    <h2 className='text-lg font-semibold text-gray-800'>Capture Image as Proof</h2>
                    <p className='text-gray-600 mt-1 mb-2'>Use your camera to take a photo for session closure.</p>
                    <input
                        id='camera'
                        type='file'
                        hidden
                        accept='image/*'
                        capture='camera' // Enables camera input
                        onChange={(e) => setImage(e.target.files[0])}
                    />
                </label>

                {image && (
                    <div className='mt-4'>
                        <img
                            src={URL.createObjectURL(image)}
                            alt='Captured Proof'
                            className='w-full h-full object-cover rounded-md border-2 border-gray-300 shadow-md'
                        />
                    </div>
                )}
            </div>

            {/* Date and Time */}
            <div className='bg-blue-100 p-4 rounded-lg shadow-md mt-4 w-[88%] max-w-sm mx-auto text-center'>
                <p className='text-lg font-semibold text-primary'>Current Time: {currentTime}</p>
                <p className='text-lg font-semibold text-primary'>Date: {currentDate}</p>
            </div>

            {/* Notes Input */}
            <div className='bg-green-100 p-4 rounded-lg shadow-md mt-4 w-[88%] max-w-sm mx-auto text-center'>
                <h2 className='text-lg font-semibold text-green-700'>Add Notes (Optional)</h2>
                <textarea
                    className='mt-4 p-2 w-full h-32 border border-gray-300 rounded-md resize-none'
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    placeholder='Describe the work done or add comments...'
                />
            </div>

            {/* Close Session Button */}
            <button
                onClick={handleCloseSession}
                className='bg-red-500 text-white font-SUSE font-bold px-6 py-3 rounded-lg hover:bg-red-600 transition duration-300 ease-in-out w-[88%] mt-8 flex items-center justify-center'>
                <MdClose className='mr-2 text-2xl' />
                {loading ? 'Closing...' : 'Close Session'}
            </button>

            {/* Hidden Canvas for Image Processing */}
            <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>

            {/* Footer */}
            <footer className='mt-auto py-4 text-sm text-gray-400'>
                &copy; 2024 nerdtech. All rights reserved.
            </footer>
        </div>
    );
}
