import React, { Suspense, lazy } from 'react';
import SideBar from '../../components/Sidebar';
import { MdSupportAgent } from "react-icons/md";
import { ThreeDots } from 'react-loader-spinner';
// Lazy load your components
const Dashboard = lazy(() => import('./Dashboard'));
const Section = lazy(() => import('./Section'));

export default function Main() {
    const [path, setPath] = React.useState("dasboard")
    const renderComponent = () => {
        switch (path) {
            case 'dashboard':
                return <Dashboard />;
            case 'section':
                return <Section />;
            default:
                return <Dashboard />;
        }
    };
    React.useEffect(() => {
        setPath('dashboard')
    }, [])

    return (
        <div className='w-screen bg-slate-100 h-[100%] flex justify-between font-Poppins'>
            <SideBar setPath={setPath} />
            <a
                href='tel:+919405649047' // Replace with the actual support number
                className='fixed bottom-4 right-4 bg-accent text-white font-SUSE font-bold px-4 py-4 rounded-full shadow-lg hover:bg-green-600 transition duration-300 ease-in-out'>
                <MdSupportAgent className=' text-2xl' />
            </a>
            <div className="flex-1 p-4">
                {/* Use Suspense to handle loading state */}
                <Suspense fallback={
                    <div
                        className='w-full h-full flex justify-center items-center'>
                        <ThreeDots
                            visible={true}
                            height="40"
                            width="40"
                            color="#007bff"
                            radius="5"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                    </div>
                }>
                    {/* Dynamically loaded based on the path */}
                    {renderComponent()}
                </Suspense>
            </div>
        </div>
    );
}
